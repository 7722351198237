<template>
  <div class="home " id="home">
    <!-- <img class="logo" alt="logo" :src="$store.state.isPC ? P_logo : M_logo"> -->
		<div class="M_box" v-show="isPlay">
			<img src="../assets/play.png" alt="" @click="playVideo">
		</div>
    <video
      class="video_el"
      :src="$store.state.isPC ? P_src : M_src"
      ref="videoPlayer"
			preload="auto"
			loop
			id="movieVideo"
			controlsList=" nodownload noplaybackrate "
			webkit-playsinline="true"
			playsinline="true"
			x-webkit-airplay="true"
			x5-video-player-type="h5"
			x5-video-player-fullscreen="true"
			x5-video-orientation="portraint"
			@loadeddata="loadeddataFun"
    ></video>
		<p class="footer">copyright © frameblank.com</p>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
	name: "HomeView",
	data() {
		return {
			P_src: "https://static.ice.design/frameblank/video.mp4",
			M_src: "https://static.ice.design/frameblank/m_video.mp4",
			P_logo: require("../assets/P_LOGO.png"),
			M_logo: require("../assets/M_LOGO.png"),
			isPlay: true
		}
	},
	methods: {
		loadeddataFun() {
			this.$nextTick(() => {
				// if (this.$store.state.isPC) {
				// 	let video = document.querySelector('#movieVideo');
				// 	let promise = video.play();
				// 	if (promise !== undefined) {
				// 		promise.then(_ => {
				// 			// Autoplay started!
				// 		}).catch(error => {
				// 			// Autoplay not allowed!
				// 			// Mute video and try to play again
				// 			video.muted = true;
				// 			video.play();
				// 			// Show something in the UI that the video is muted
				// 		});
				// 	}
				// }
			});
		},
		playVideo() {
			this.isPlay = false;
			document.querySelector('#movieVideo').play();
		}
	},
	mounted () {
		window.οncοntextmenu = function() {
			return false;
		};
	},
};
</script>

<style lang="scss" scoped>
.home,
.video_el,
.M_box {
  width: 100%;
  height: 100%;
}
.home {
	position: relative;
	.logo {
		width: 207px;
		height: 144px;
		display: block;
		position: fixed;
		left: 50%;
		top: 50px;
		transform: translateX(-50%);
		z-index: 1;
	}
	.video_el {
		object-fit: cover;
		display: block;
		background: #000;
	}
	.footer {
		font-size: 15px;
		// font-family: Microsoft YaHei;
		font-weight: 600;
		color: #FFFFFF;
		text-align: center;
		position: fixed;
		left: 0;
		bottom: 26px;
		width: 100%;
		z-index: 2;
	}
	.M_box {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
		img {
			width: 110px;
			height: 110px;
			cursor: pointer;
		}
	}
	@media only screen and (max-width: 767px) {
		.logo {
			width: 111px;
			height: 77px;
		}
		.footer {
			font-size: 12px;
			font-weight: 600;
		}
		.M_box {
			background: #000;
			img {
				width: 60px;
				height: 60px;
			}
		}
	}
	video::-internal-media-controls-download-button {
    display:none;
	}
	video::-webkit-media-controls-enclosure {
			overflow:hidden;
	}
	video::-webkit-media-controls-panel {
			width: calc(100% + 30px); 
	}

}
</style>